<template>
  <validation-observer v-slot="{ handleSubmit }" ref="observer">
    <b-form
      @submit.prevent="handleSubmit(change)"
      class="w-100 h-100 p-4 d-flex flex-column justify-content-between"
    >
      <div class="row">
        <div class="col-lg-12 mb-12">
          <label for="descricao" class="form-label campo-obrigatorio"
            >Descrição</label
          >
          <validation-provider
            name="descricao"
            :rules="descricaoCadastrada"
            v-slot="{ classes, errors }"
          >
            <input
              type="text"
              id="descricao"
              v-model="mercadoria.descricao"
              class="form-control"
              :class="classes"
              maxlength="155"
            />
            <div
              class="invalid-feedback"
              v-for="error in errors"
              v-bind:key="error"
            >
              <span class="erro">{{ error }}</span>
            </div>
          </validation-provider>
        </div>
      </div>

      <div class="pt-4 w-100 d-flex justify-content-center">
        <router-link class="btn btn-light" :to="{ name: 'MercadoriasPesquisar' }"
          ><i class="fas fa-times"></i> Cancelar</router-link
        >
        <button type="submit" class="btn btn-success">
          <i class="fas fa-check"></i> Salvar
        </button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { rules } from "@/validators/mercadoria.js";

export default {
  props: {
    mountedForm: {
      type: Object,
    },
  },
  data: function () {
    return {
      rules,
      descricaoCadastrada: "required|descricaoCadastrada:",
      mercadoria: {
        descricao: "",
      },
    };
  },
  methods: {
    reset() {
      this.mercadoria.descricao = null;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    change() {
      this.$emit("change", this.mercadoria.descricao);
    },
  },
  created() {
    if (this.mountedForm) {
      this.descricaoCadastrada = "required|descricaoCadastrada:" + this.mountedForm.descricao,
      this.mercadoria.descricao = this.mountedForm.descricao;
    }
  },
};
</script>
